import React, { ReactElement, useEffect } from "react";
import { useAtom } from "jotai";
import { authAtom } from "../../auth/auth.atom";
import { useNavigate } from "react-router";
import { axios } from "../../axios";
import { User } from "../../models/User";

export const WithAuth = ({
  children,
}: {
  children: ChildNode | ReactElement;
}) => {
  const [auth, setAuth] = useAtom(authAtom);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(auth.user);
    if (auth.loaded && !auth.authenticated) {
      navigate("/login");
    } else if (!auth.loaded) {
      axios
        .get("/auth/user/me")
        .then((result) => {
          if (result.status === 200) {
            setAuth({
              authenticated: true,
              user: User.fromJson(result.data.user ?? result.data),
              loaded: true,
            });
          } else {
            throw new Error();
          }
        })
        .catch((e) => {
          console.log(e);
          setAuth({ authenticated: false, user: null, loaded: true });
        });
    } else if (
      auth.user &&
      (!auth.user.verifiedEmail || !auth.user.verifiedMobile) &&
      auth.user.mobile?.code &&
      auth.user.mobile?.phone
    ) {
      navigate("/verify");
    }
  }, [auth]);
  return <>{children}</>;
};
