import { atom } from "jotai";
import { User } from "../models/User";

export const authAtom = atom<{
  authenticated: boolean;
  user: User | null;
  loaded: boolean;
}>({
  authenticated: false,
  user: null,
  loaded: false,
});
