import { useCallback, useEffect } from "react";
import styled from "styled-components";
import { Spacer } from "../../components/spacer/spacer.component";
import { axios } from "../../axios";
import { wait } from "../../utils/wait";
import { Button, Form, Input } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { Label } from "../../components/label/label.component";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { AuthForm } from "../../components/auth-form/auth-form.component";
import { AxiosError } from "axios";
import { showMessage } from "../../utils/showMessage";
import toast from "react-hot-toast";

export const ResetPasswordPage = () => {
  const [query] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (!query.get("token")) {
      navigate("/");
    }
  }, [query]);

  const onSubmit = useCallback(
    async ({
      password,
      confirm_password,
    }: {
      password: string;
      confirm_password: string;
    }) => {
      if (password != confirm_password) {
        toast.error("The two passwords do not match!");
        return;
      }
      try {
        const resetPasswordDto = {
          reset_token: query.get("token"),
          new_password: password,
        };

        const result = await axios.post(
          "/auth/password/reset/verify",
          resetPasswordDto
        );
        if (result.status === 200) {
          navigate("/login");
          await wait(1000);
          showMessage(result);
        }
      } catch (e) {
        showMessage(e as AxiosError, "error");
      }
    },
    [query]
  );

  const [form] = Form.useForm();

  return (
    <AuthForm form={form} onSubmit={onSubmit}>
      <MobileCol>
        <Label htmlFor="password">Password</Label>
        <Spacer size={5} />
        <Form.Item
          name="password"
          rules={[
            {
              type: "string",
              min: 8,
              validator: async (_, password: string) => {
                if (!password.length)
                  throw new Error("Please enter a password!");
                if (password.length < 8)
                  throw new Error("Password must be at least 8 characters!");
              },
            },
          ]}
        >
          <Input
            type="password"
            id="password"
            size="large"
            autoFocus={true}
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
          />
        </Form.Item>
        <Label htmlFor="confirm_password">Confirm Password</Label>
        <Spacer size={5} />
        <Form.Item
          name="confirm_password"
          rules={[
            {
              type: "string",
              min: 8,
              validator: async (_, password: string) => {
                if (!password.length)
                  throw new Error("Please enter a password!");
                if (password.length < 8)
                  throw new Error("Password must be at least 8 characters!");
              },
            },
          ]}
        >
          <Input
            type="password"
            id="confirm_password"
            size="large"
            autoFocus={true}
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Confirm Password"
          />
        </Form.Item>
      </MobileCol>
      <Spacer size={20} />
      <Form.Item style={{ width: "100%" }}>
        <Button
          block
          type="primary"
          size="large"
          shape="round"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Reset Password
        </Button>
      </Form.Item>
      <Spacer size={-10} />
      <RegisterText>
        <p
          onClick={() =>
            navigate("/login" + (query.get("r") ? `?r=${query.get("r")}` : ""))
          }
        >
          Go back
        </p>
      </RegisterText>
    </AuthForm>
  );
};

const MobileCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RegisterText = styled.h3`
  font-size: 1.6rem;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  text-align: center;

  & text {
    color: #2268bd;
    cursor: pointer;
  }
`;
