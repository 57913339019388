export const redactText = (
  text: string,
  nUnredacted: number,
  options?: { before?: string; redactedCharacter?: string }
) => {
  const [rawText, appendix = ""] = options?.before
    ? text.split(options?.before)
    : [text];
  const rawTextLength = rawText.length;

  let finalText = "";
  const nUnredactedStart = Math.ceil(nUnredacted / 2),
    nUnredactedEnd = nUnredacted - nUnredactedStart;

  for (let i = 0; i < rawText.length; i++) {
    if (i < nUnredactedStart || i >= rawTextLength - nUnredactedEnd) {
      finalText += rawText[i];
    } else {
      finalText += options?.redactedCharacter ?? "*";
    }
  }

  return finalText + (options?.before ?? "") + appendix;
};
