import { useAtom } from "jotai";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { authAtom } from "../../auth/auth.atom";
import { axios } from "../../axios";
import { wait } from "../../utils/wait";
import toast, { Toaster } from "react-hot-toast";
import { User } from "../../models/User";
import { showMessage } from "../../utils/showMessage";
import { AxiosError } from "axios";

export const GooglePage = () => {
  const [auth, setAuth] = useAtom(authAtom);

  const [query] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    let token = query.get("token");
    if (token) {
      axios
        .get("/user/me?setCookie=true", { headers: { Authorization: token } })
        .then(() => {
          return axios.get("/auth/google/setCookie", {
            headers: { Authorization: token },
          });
        })
        .then((result) => {
          showMessage(result);
          setAuth({
            authenticated: true,
            loaded: true,
            user: User.fromJson(result.data.user ?? result.data),
          });
        })
        .catch(async (e) => {
          showMessage(e as AxiosError, "error");
          await wait(1000);
          navigate("/login");
        });
    }
  }, [query]);

  return (
    <>
      <Toaster />
    </>
  );
};
