import { RouterProvider } from "react-router-dom";
import { router } from "./routes/router";
import "./App.css";
import { ConfigProvider } from "antd";

import "antd-country-phone-input/dist/index.css";

function App() {
  return (
    <ConfigProvider>
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
