export const config = {
  API_BASE_URL:
    process.env.REACT_APP_API_BASE_URL ??
    (process.env.NODE_ENV === "production"
      ? "https://f672-150-107-16-228.ngrok-free.app/api/v1"
      : "https://f672-150-107-16-228.ngrok-free.app/api/v1"),
  APP_BASE_URL:
    process.env.REACT_APP_APP_BASE_URL ??
    (process.env.NODE_ENV === "production"
      ? "https://auth.pocketfilms.in"
      : "http://localhost:3000"),
  PLAY_APP_URL: process.env.REACT_APP_PLAY_URL ?? "https://play.pocketfilms.in",
  DISTRIBUTE_APP_URL:
    process.env.REACT_APP_DISTRIBUTE_URL ?? "https://distribute.pocketfilms.in",
};
