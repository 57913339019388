import { AxiosError, AxiosResponse } from "axios";
import toast from "react-hot-toast";

interface MessageResponse {
  message?: string;
}

export const showMessage = (
  result: AxiosResponse<MessageResponse> | AxiosError<MessageResponse> | Error,
  type: "success" | "error" = "success"
) => {
  return toast[type](
    (result instanceof AxiosError
      ? result.response?.data?.message
      : result instanceof Error
      ? "There was an error!"
      : result.data.message) ??
      (type === "success" ? "Success!" : "There was an error!")
  );
};
