import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { HomePage } from "../pages/home/home.page";
import { LoginPage } from "../pages/login/login.page";
import { SignupPage } from "../pages/signup/signup.page";
import { NoAuth } from "../components/no-auth/no-auth.component";
import { GooglePage } from "../pages/google/google.page";
import { ForgotPasswordPage } from "../pages/forgot-password/forgot-password.page";
import { OtpPage } from "../pages/otp/otp.page";
import { VerifyPage } from "../pages/verify/verify.page";
import { WithAuth } from "../components/with-auth/with-auth.component";
import { ResetPasswordPage } from "../pages/forgot-password/reset-password.page";
import LogoutPage from "../pages/logout/logout-page";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/login",
    element: (
      <NoAuth>
        <LoginPage />
      </NoAuth>
    ),
  },
  {
    path: "/login/otp",
    element: (
      <NoAuth>
        <OtpPage />
      </NoAuth>
    ),
  },
  {
    path: "/verify",
    element: (
      <WithAuth>
        <VerifyPage />
      </WithAuth>
    ),
  },
  {
    path: "/get-started",
    element: (
      <NoAuth>
        <SignupPage />
      </NoAuth>
    ),
  },
  {
    path: "/google",
    element: (
      <NoAuth>
        <GooglePage />
      </NoAuth>
    ),
  },
  {
    path: "/forgot-password",
    element: (
      <NoAuth>
        <ForgotPasswordPage />
      </NoAuth>
    ),
  },
  {
    path: "/reset-password",
    element: (
      <NoAuth>
        <ResetPasswordPage />
      </NoAuth>
    ),
  },
  {
    path: "/logout",
    element: <LogoutPage />,
  },
]);
