import { useCallback, useState } from "react";
import styled from "styled-components";
import { Spacer } from "../../components/spacer/spacer.component";
import { axios } from "../../axios";
import { isNumeric } from "../../utils/isNumeric";
import { useSetAtom } from "jotai";
import { authAtom } from "../../auth/auth.atom";
import { UserInterface } from "../../interfaces/user.interface";
import { User } from "../../models/User";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { wait } from "../../utils/wait";
import { Button, Form, Input } from "antd";
import CountryPhoneInput from "antd-country-phone-input";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Label } from "../../components/label/label.component";
import { useNavigate } from "react-router";
import { config } from "../../config";
import { Link, useSearchParams } from "react-router-dom";
import { AuthForm } from "../../components/auth-form/auth-form.component";
import { showMessage } from "../../utils/showMessage";

export const LoginPage = () => {
  const setAuth = useSetAtom(authAtom);
  const navigate = useNavigate();

  const [search] = useSearchParams();

  const [countryCode, setCountryCode] = useState({ code: 91, short: "IN" });
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = useCallback(
    async ({
      email,
      mobile,
      password,
    }: {
      email?: string;
      mobile?: { phone: string; code: number; short: string };
      password: string;
    }) => {
      try {
        const loginDto = {
          email,
          phone: mobile ? { code: mobile.code, number: mobile.phone } : null,
          password,
        };

        const result = await axios.post<{
          token: string;
          user: UserInterface;
          message?: string;
        }>("/auth/login", loginDto);
        if (result.status === 200) {
          showMessage(result);
          await wait(1000);
          setAuth({
            authenticated: true,
            user: User.fromJson(result.data.user),
            loaded: true,
          });
        }
      } catch (e) {
        showMessage(e as AxiosError, "error");
      }
    },
    []
  );

  const [form] = Form.useForm();

  const isPhone = phone !== "";

  return (
    <AuthForm form={form} onSubmit={onSubmit}>
      <MobileCol style={{ width: "100%" }}>
        <Label htmlFor={phone === "" ? "email" : "mobile-number"}>
          {phone === "" && email === ""
            ? "Phone Number / Email"
            : isPhone
            ? "Phone Number"
            : "Email"}
        </Label>
        <Spacer size={5} />
        {isPhone ? (
          <Form.Item
            name="mobile"
            initialValue={{ short: "IN", phone, code: 91 }}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Mobile number is required",
              },
              {
                validator: (_, value) =>
                  /^[0-9]{8,14}$/.test(value?.phone)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("Please input valid contact number!")
                      ),
              },
            ]}
          >
            <CountryPhoneInput
              id="mobile-number"
              autoFocus={true}
              size="large"
              style={{ width: "100%" }}
              value={{
                phone,
                code: countryCode.code,
                short: countryCode.short,
              }}
              onChange={(evt) => {
                setPhone(evt.phone!);
                setCountryCode({ code: evt.code!, short: evt.short! });
              }}
            />
          </Form.Item>
        ) : (
          <Form.Item
            name="email"
            initialValue={email}
            style={{ width: "100%" }}
            rules={[
              {
                type: "email",
                required: true,
                message: `Please input your ${
                  email === "" ? "email address/phone number" : "email address"
                }!`,
                transform: (value) => value.toLowerCase(),
              },
            ]}
          >
            <Input
              id="email"
              size="large"
              value={email}
              style={{ width: "100%" }}
              autoFocus={true}
              onChange={(evt) => {
                const newValue = evt.target.value;
                if (isNumeric(newValue[0])) {
                  setEmail("");
                  setPhone(newValue);
                } else {
                  setEmail(newValue);
                }
              }}
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Email or Phone Number"
            />
          </Form.Item>
        )}
        <Label htmlFor="password">Password</Label>
        <Spacer size={5} />
        <Form.Item
          name="password"
          rules={[
            {
              type: "string",
              required: true,
              message: "Please input your Password!",
            },
            {
              min: 8,
              max: 24,
              message: "Password should be at least 8 characters!",
            },
            {
              pattern: /^[a-zA-Z0-9<>!@#$%^&{}[\]()?_+-=]+$/,
              message: "Invalid characters entered!",
            },
          ]}
        >
          <Input.Password
            size="large"
            id="password"
            placeholder="Password"
            prefix={<LockOutlined className="site-form-item-icon" />}
            value={password}
            onChange={(evt) => setPassword(evt.target.value)}
          />
        </Form.Item>
      </MobileCol>
      <Spacer size={-10} />
      <Row>
        <Link
          to={"/login/otp" + (search.get("r") ? `?r=${search.get("r")}` : "")}
        >
          <ForgotPassword>Sign in via OTP</ForgotPassword>
        </Link>
        <Link
          to={
            "/forgot-password" +
            (search.get("r") ? `?r=${search.get("r")}` : "")
          }
        >
          <ForgotPassword>Forgot Password?</ForgotPassword>
        </Link>
      </Row>
      <Spacer size={20} />
      <Form.Item style={{ width: "100%" }}>
        <Button
          block
          type="primary"
          size="large"
          shape="round"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Sign in
        </Button>
      </Form.Item>
      <RegisterText>
        <p
          onClick={() =>
            navigate(
              "/get-started" + (search.get("r") ? `?r=${search.get("r")}` : "")
            )
          }
        >
          New to PocketFilms?
          <br />
          Register
        </p>
      </RegisterText>
      <Spacer size={10} />
      <OrContainer>
        <Line></Line>
        <OrText>Or</OrText>
        <Line></Line>
      </OrContainer>
      <Spacer size={10} />
      <GoogleButton href={`${config.API_BASE_URL}/auth/google/login`}>
        <GoogleIcon />
      </GoogleButton>
    </AuthForm>
  );
};

const MobileCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ForgotPassword = styled.span`
  text-align: right;
  font-size: 1.6rem;
  color: #2268bd;
  font-weight: 600;
  cursor: pointer;
  font-style: italic;
  align-self: flex-end;
`;

const RegisterText = styled.h3`
  font-size: 1.6rem;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  text-align: center;

  & text {
    color: #2268bd;
    cursor: pointer;
  }
`;

const OrContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const Line = styled.div`
  height: 1px;
  background-color: #262525;
  flex: 1;
`;

const OrText = styled(RegisterText)`
  opacity: 0.8;
  text-transform: uppercase;
`;

const GoogleButton = styled.a`
  padding: 1.5rem;
  cursor: pointer;
  border-radius: 50%;
  background-color: #141314;
  transition: transform 0.1s ease-in-out;

  &:hover {
    transform: translateY(-0.5rem);
  }
`;

const GoogleIcon = styled.img.attrs({ src: "/images/google.png" })`
  width: 3rem;
  height: 3rem;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media only screen and (max-width: 31.25em) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;
