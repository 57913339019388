import moment from "moment";
import { UserInterface } from "../interfaces/user.interface";
import { Model } from "./Model";

export class User extends Model<UserInterface> {
  constructor(
    public id: string,
    public firstName: string,
    public lastName: string,
    public gender: "male" | "female" | "other",
    public email: string,
    public mobile: { code: string; phone: string } | null,
    public birthdate: Date | null,
    public status: "active" | "inactive" | "unverified",
    public address1: string,
    public address2: string,
    public country: string,
    public state: string,
    public city: string,
    public pincode: string,
    public verifiedEmail: boolean,
    public verifiedMobile: boolean,
    public submitAsBusiness: boolean,
    public subscribeMobile: boolean,
    public subscribeNewsletter: boolean
  ) {
    super();
  }

  public toJson() {
    return {
      id: this.id,
      first_name: this.firstName,
      last_name: this.lastName,
      gender: this.gender,
      email: this.email,
      phone_code: this.mobile?.code ?? "",
      phone_number: this.mobile?.phone ?? "",
      date_of_birth: moment(this.birthdate).format("YYYY-MM-DD"),
      status: this.status,
      address_line1: this.address1,
      address_line2: this.address2,
      country: this.country,
      state: this.state,
      city: this.city,
      postal_code: this.pincode,
      email_verified: this.verifiedEmail,
      phone_verified: this.verifiedMobile,
      submit_as_business: this.submitAsBusiness,
      subscribe_to_mobile_notifications: this.subscribeMobile,
      subscribe_to_newsletters: this.subscribeNewsletter,
    } as UserInterface;
  }

  public static fromJson(json: UserInterface) {
    return new User(
      json.id,
      json.first_name,
      json.last_name,
      json.gender,
      json.email,
      { code: json.phone_code, phone: json.phone_number },
      json.date_of_birth ? new Date(json.date_of_birth) : null,
      json.status,
      json.address_line1,
      json.address_line2,
      json.country,
      json.state,
      json.city,
      json.postal_code,
      json.email_verified,
      json.phone_verified,
      json.submit_as_business,
      json.subscribe_to_mobile_notifications,
      json.subscribe_to_newsletters
    );
  }
}
