import { useCallback, useEffect } from "react";
import styled from "styled-components";
import { axios } from "../../axios";
import { useAtomValue, useSetAtom } from "jotai";
import { authAtom } from "../../auth/auth.atom";
import { UserInterface } from "../../interfaces/user.interface";
import toast from "react-hot-toast";
import { Button, Form, Input, Modal } from "antd";
import { useNavigate } from "react-router";
import { AuthForm } from "../../components/auth-form/auth-form.component";
import { showMessage } from "../../utils/showMessage";
import { AxiosError } from "axios";
import { redactText } from "../../utils/redactText";
import { useSearchParams } from "react-router-dom";
import { User } from '../../models/User';

export const VerifyPage = () => {
  const [searchParams] = useSearchParams();

  const [form] = Form.useForm();
  const [modal, contextHolder] = Modal.useModal();
  const auth = useAtomValue(authAtom);
  let allowMobileOTPSend = true;
  let allowEmailOTPSend = true;

  const setAuth = useSetAtom(authAtom);
  const navigate = useNavigate();

  function sendOTP(channel: "SMS" | "WHATSAPP") {
    if (auth.user?.verifiedMobile) {
      toast.success("Your Mobile OTP is verified, leave the field blank");
    } else {
      if (auth.authenticated && allowMobileOTPSend) {
        allowMobileOTPSend = false;
        const sendOtpDto = {
          channel,
          phone: {
            code: auth.user?.mobile?.code,
            number: auth.user?.mobile?.phone,
          },
        };

        axios
          .post("/auth/otp/send/phone", sendOtpDto)
          .then((result) => {
            allowMobileOTPSend = true;
            showMessage(result, "success");
          })
          .catch((e) => {
            allowMobileOTPSend = true;
            showMessage(e as AxiosError, "error");
          });
      }
    }
  }

  function sendEmailOTP() {
    if (auth.user?.verifiedEmail) {
      toast.success("Your Email OTP is verified, leave the field blank");
    } else {
      if (auth.authenticated && allowEmailOTPSend) {
        allowEmailOTPSend = false;
        const sendOtpDto = {
          email: auth.user?.email,
        };

        axios
          .post("/auth/otp/send/email", sendOtpDto)
          .then((result) => {
            allowEmailOTPSend = true;
            showMessage(result, "success");
          })
          .catch((e) => {
            allowEmailOTPSend = true;
            showMessage(e as AxiosError, "error");
          });
      }
    }
  }

  const onSubmit = useCallback(
    async ({
      otpMobile,
      otpEmail,
    }: {
      otpMobile: string;
      otpEmail: string;
    }) => {
      let emailVerified = auth.user?.verifiedEmail
      let phoneVerified = auth.user?.verifiedMobile
      if (auth.user?.mobile?.phone == null){
        navigate(searchParams.get("r") || "/");
      }
      try {
        if (!auth.user?.verifiedMobile){
          const verifyPhoneOtpDto = {
            phone: {
              code: auth.user?.mobile?.code,
              number: auth.user?.mobile?.phone,
            },
            otp: otpMobile,
          };
          axios.post(
            "/auth/otp/verify/phone",
            verifyPhoneOtpDto
          ).then((result) => {
            if (result.status == 200){
              phoneVerified = true
              if (emailVerified){
                setAuth({
                  authenticated: true,
                  user: User.fromJson(result.data.user ?? result.data),
                  loaded: true,
                });
                navigate(searchParams.get("r") || "/");
              }
              toast.success("Phone OTP Verified Successfully");
            }
          }).catch((e) => {
            allowMobileOTPSend = true;
            showMessage(e as AxiosError, "error");
          });
        }
        if (!auth.user?.verifiedEmail){
          const verifyEmailOtpDto = {
            email: auth.user?.email,
            otp: otpEmail,
          };
          axios.post(
            "/auth/otp/verify/email",
            verifyEmailOtpDto
          ).then((result) => {
            if (result.status == 200){
              emailVerified = true
              toast.success("Email OTP Verified Successfully");
              if (phoneVerified){
                setAuth({
                  authenticated: true,
                  user: User.fromJson(result.data.user ?? result.data),
                  loaded: true,
                });
                navigate(searchParams.get("r") || "/");
              }
            }
          }).catch((e) => {
            allowMobileOTPSend = true;
            showMessage(e as AxiosError, "error");
          });
        }
      } catch (e) {
        showMessage(e as AxiosError, "error");
      }
    },
    [searchParams]
  );

  useEffect(() => {
    if (auth.user?.verifiedEmail && auth.user?.verifiedMobile) {
      navigate(searchParams.get("r") || "/");
    }
  }, [auth, searchParams]);

  return (
    <AuthForm form={form} onSubmit={onSubmit}>
      {contextHolder}
      <MobileCol style={{ width: "100%" }}>
        {!auth.user?.verifiedMobile ? (
          <>
            <SentToText>{`Sent to +${auth.user?.mobile?.code}${redactText(
              auth.user?.mobile?.phone ?? "",
              4
            )}`}</SentToText>
            <Form.Item
              name="otpMobile"
              rules={[
                {
                  type: "string",
                  required: !auth.user?.verifiedMobile,
                  message: "Please input your otp!",
                },
                {
                  pattern: /[0-9]+$/,
                  message: "OTP can only have numeric characters!",
                },
                {
                  validator: (_, value) => {
                    if (value && value.length > 6) {
                      return Promise.reject(
                        new Error(
                          "OTP can have a maximum length of 6 characters!"
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                size="large"
                id="mobile-otp"
                placeholder="Mobile OTP"
                addonAfter={
                  <Button
                    type="primary"
                    onClick={(event) => {
                      modal.confirm({
                        title: "Resend OTP via",
                        okText: "SMS",
                        cancelText: "WhatsApp",
                        onCancel: () => {
                          sendOTP("WHATSAPP");
                        },
                        onOk: () => {
                          sendOTP("SMS");
                        },
                      });
                    }}
                  >
                    Resend
                  </Button>
                }
              />
            </Form.Item>
          </>
        ) : null}
        {!auth.user?.verifiedEmail ? (
          <>
            <SentToText>{`Sent to ${redactText(auth.user?.email ?? "", 5, {
              before: "@",
            })}`}</SentToText>
            <Form.Item
              name="otpEmail"
              rules={[
                {
                  type: "string",
                  required: !auth.user?.verifiedEmail,
                  message: "Please input your OTP!",
                },
                {
                  len: 6,
                  message: "OTP should be 6 digits long!",
                },
                {
                  pattern: /[0-9]+$/,
                  message: "OTP can only have numeric characters!",
                },
              ]}
            >
              <Input
                size="large"
                id="email-otp"
                placeholder="Email OTP"
                addonAfter={
                  <Button
                    type="primary"
                    onClick={(event) => {
                      sendEmailOTP();
                    }}
                  >
                    Resend
                  </Button>
                }
              />
            </Form.Item>
          </>
        ) : null}
      </MobileCol>
      <Form.Item style={{ width: "100%" }}>
        <Button
          block
          type="primary"
          size="large"
          shape="round"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Continue
        </Button>
      </Form.Item>
      <RegisterText>
        <p
          onClick={async () => {
            await axios.post("/auth/logout");
            navigate("/");
          }}
        >
          Logout
        </p>
      </RegisterText>
    </AuthForm>
  );
};

const MobileCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RegisterText = styled.h3`
  font-size: 1.6rem;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  text-align: center;

  & text {
    color: #2268bd;
    cursor: pointer;
  }
`;

const SentToText = styled.span`
  color: #fff;
  opacity: 0.75;
  font-size: 1.4rem;
  font-weight: 500;
`;
