import { useCallback } from "react";
import styled from "styled-components";
import { Spacer } from "../../components/spacer/spacer.component";
import { axios } from "../../axios";
import toast from "react-hot-toast";
import { wait } from "../../utils/wait";
import { Button, Form, Input } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Label } from "../../components/label/label.component";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { AuthForm } from "../../components/auth-form/auth-form.component";
import { showMessage } from "../../utils/showMessage";
import { AxiosError } from "axios";

export const ForgotPasswordPage = () => {
  const [query] = useSearchParams();

  const navigate = useNavigate();

  const onSubmit = useCallback(async (resetDto: { email: string }) => {
    try {
      const result = await axios.post(
        "/auth/password/reset/send/email",
        resetDto
      );
      if (result.status === 200) {
        navigate("/login");
        await wait(1000);
        showMessage(result);
      }
    } catch (e) {
      showMessage(e as AxiosError, "error");
      console.log(e);
    }
  }, []);

  const [form] = Form.useForm();

  const defaultEmail = query.get("email");

  return (
    <AuthForm form={form} onSubmit={onSubmit}>
      <MobileCol>
        <Label htmlFor="email">Email Address</Label>
        <Spacer size={5} />
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              required: true,
              message: "Please input your email address!",
              transform: (value) => value.toLowerCase(),
            },
          ]}
        >
          <Input
            id="email"
            size="large"
            autoFocus={true}
            defaultValue={defaultEmail ?? ""}
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Email"
          />
        </Form.Item>
      </MobileCol>
      <Spacer size={20} />
      <Form.Item style={{ width: "100%" }}>
        <Button
          block
          type="primary"
          size="large"
          shape="round"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Send reset link
        </Button>
      </Form.Item>
      <Spacer size={-10} />
      <RegisterText>
        <p
          onClick={() =>
            navigate("/login" + (query.get("r") ? `?r=${query.get("r")}` : ""))
          }
        >
          Go back
        </p>
      </RegisterText>
      <br />
      <br />
      <br />
    </AuthForm>
  );
};

const MobileCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RegisterText = styled.h3`
  font-size: 1.6rem;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  text-align: center;

  & text {
    color: #2268bd;
    cursor: pointer;
  }
`;
