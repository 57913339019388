import React from "react";
import { Form, FormInstance } from "antd";
import { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
import { Toaster } from "react-hot-toast";
import { Spacer } from "../spacer/spacer.component";
import styled from "styled-components";

export const AuthForm = <T extends Record<string, any>>({
  form,
  onSubmit,
  children,
}: {
  form: FormInstance<T>;
  onSubmit: (data: T) => void;
  children: React.ReactElement | React.ReactElement[];
}) => {
  return (
    <Container>
      <Toaster />
      <FormContainer>
        <Form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
          form={form}
          name="login"
          className="w-75"
          onFinish={onSubmit}
        >
          <ConfigProvider locale={en}>
            <Logo />
            <Spacer size={30} />
            {children}
          </ConfigProvider>
        </Form>
      </FormContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("/images/login-background-image.webp");
  background-size: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 4.5rem;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(12px);
  min-width: 27.5vw;
  border-radius: 1.5rem;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 50% 100%, 0% 90%);

  /* @media only screen and (max-width: 58.125em) {
    width: 50vw;
  }
  @media only screen and (max-width: 43.75em) {
    width: 70vw;
  } */
  @media only screen and (max-width: 25em) {
    width: 100vw;
    backdrop-filter: none;
    background-color: #000;
    height: 100vh;
    clip-path: none;
  }
`;

const Logo = styled.img.attrs({
  src: "/images/logo.webp",
})`
  height: 6.4rem;
`;
