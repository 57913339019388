import { useAtom } from "jotai";
import React, { ReactElement, useEffect } from "react";
import { authAtom } from "../../auth/auth.atom";
import { useNavigate } from "react-router";
import { axios } from "../../axios";
import { User } from "../../models/User";

export const NoAuth = ({
  children,
}: {
  children: ChildNode | ReactElement;
}) => {
  const [auth, setAuth] = useAtom(authAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.loaded && auth.authenticated) {
      navigate("/"); // Redirect to target application
    } else if (!auth.loaded) {
      axios
        .get("/auth/user/me")
        .then((result) => {
          if (result.status === 200) {
            setAuth({
              authenticated: true,
              user: User.fromJson(result.data.user ?? result.data),
              loaded: true,
            });
          } else {
            throw new Error();
          }
        })
        .catch((e) => {
          setAuth({ authenticated: false, user: null, loaded: true });
        });
    }
  }, [auth]);

  return <>{children}</>;
};
