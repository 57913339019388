import { useAtom } from "jotai";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authAtom } from "../../auth/auth.atom";
import { axios } from "../../axios";
import styled from "styled-components";
import { UserInterface } from "../../interfaces/user.interface";
import { User } from "../../models/User";
import { config } from "../../config";

export const HomePage = () => {
  const [auth, setAuth] = useAtom(authAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      auth.loaded &&
      auth.authenticated &&
      (auth.user?.verifiedEmail || auth.user?.email === null || !auth.user) &&
      (auth.user?.verifiedMobile ||
        auth.user?.mobile?.code === null ||
        auth.user?.mobile?.phone === null ||
        !auth.user)
    ) {
      window.location.replace(config.DISTRIBUTE_APP_URL);
      // const redirect = (query.get("r") ??
      //   localStorage.getItem("redirect_to") ??
      //   "distribute") as "play" | "distribute";
      // switch (redirect) {
      //   case "play":
      //     window.location.replace(config.PLAY_APP_URL);
      //     // window.location.replace(process.env.REACT_APP_CLIENT_APP_FQDN + "/partner");
      //     break;
      //   case "distribute":
      //     window.location.replace(config.DISTRIBUTE_APP_URL);
      //     break;
      // }
    } else if (auth.loaded && !auth.authenticated) {
      navigate("/login");
    } else if (auth.loaded && auth.authenticated) {
      navigate("/verify");
    }
  }, [auth]);

  useEffect(() => {
    axios
      .get<{ user: UserInterface }>("/auth/user/me")
      .then((result) => {
        if (result.status === 200) {
          setAuth({
            authenticated: true,
            user: User.fromJson(result.data.user ?? result.data),
            loaded: true,
          });
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        setAuth({ authenticated: false, user: null, loaded: true });
      });
  }, []);

  return (
    <Container>
      <LoadingText>Loading...</LoadingText>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingText = styled.h2`
  font-size: 2.5rem;
  color: #fff;
  font-weight: 600;
  animation: pulseOpacity 2s infinite both ease-in-out;

  @keyframes pulseOpacity {
    0% {
      opacity: 100%;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 100%;
    }
  }
`;
