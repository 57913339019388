import React, { useEffect } from 'react';
import { axios } from "../../axios";
import { useSetAtom } from 'jotai/index';
import { authAtom } from '../../auth/auth.atom';
import { useNavigate } from 'react-router';

const LogoutPage = () => {
  const setAuth = useSetAtom(authAtom);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      axios
        .post('/auth/logout')
        .then((result) => {
          setAuth({
            authenticated: false,
            user: null,
            loaded: true,
          });
          navigate("/")
        });
    } catch (e){

    }
  }, []);

  return (
    <div>
      <p>Logging out...</p>
    </div>
  );
};

export default LogoutPage;
