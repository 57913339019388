import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Spacer } from "../../components/spacer/spacer.component";
import { axios } from "../../axios";
import { useSetAtom } from "jotai";
import { authAtom } from "../../auth/auth.atom";
import { Button, Form, Input } from "antd";
import CountryPhoneInput from "antd-country-phone-input";
import { LockOutlined } from "@ant-design/icons";
import { Label } from "../../components/label/label.component";
import { useNavigate } from "react-router";
import { config } from "../../config";
import { Link, useSearchParams } from "react-router-dom";
import { AuthForm } from "../../components/auth-form/auth-form.component";
import { UserInterface } from "../../interfaces/user.interface";
import { User } from "../../models/User";
import { wait } from "../../utils/wait";
import { ONE_MINUTE } from "../../utils/constants";
import { AxiosError } from "axios";
import modal from "antd/lib/modal";
import { showMessage } from "../../utils/showMessage";

export const OtpPage = () => {
  const [search] = useSearchParams();

  const setAuth = useSetAtom(authAtom);
  const otpResendTimer = useRef<ReturnType<typeof setInterval> | null>(null);

  const [otpSentTo, setOtpSentTo] = useState({
    mobile: { phone: "", short: "", code: 0 },
    channel: "SMS",
  });
  const [otpSent, setOtpSent] = useState(false);
  const [resendTime, setResendTime] = useState(ONE_MINUTE);

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      if (otpResendTimer.current) clearInterval(otpResendTimer.current);
    };
  }, [otpResendTimer]);

  const setTimers = useCallback(() => {
    if (otpResendTimer.current) clearInterval(otpResendTimer.current);
    const interval = setInterval(() => {
      setResendTime((resendTime) =>
        resendTime < 0 ? resendTime : resendTime - 1000
      );
    }, 1000);
    otpResendTimer.current = interval;
    setTimeout(() => {
      clearInterval(interval);
    }, ONE_MINUTE + 1000);
  }, [setResendTime, otpResendTimer]);

  const onSubmit = useCallback(
    async ({
      mobile,
      otp,
    }: {
      mobile: { phone: string; code: number; short: string };
      otp?: string;
    }) => {
      if (otpSent && otp) {
        const verifyOtpDto = {
          phone: {
            code: mobile.code,
            number: mobile.phone,
          },
          otp,
        };

        axios
          .post<{ token: string; user: UserInterface; message?: string }>(
            "auth/otp/verify/phone",
            verifyOtpDto
          )
          .then(async (result) => {
            showMessage(result);
            await wait(1000);
            setAuth({
              authenticated: true,
              loaded: true,
              user: User.fromJson(result.data.user),
            });
          })
          .catch((e) => {
            showMessage(e as AxiosError, "error");
            console.log(e);
          });
      } else {
        setOtpSent(false);
        const sendOtpDto = {
          phone: {
            code: mobile.code,
            number: mobile.phone,
          },
          channel: "SMS",
        };

        axios
          .post("/auth/otp/send/phone", sendOtpDto)
          .then((result) => {
            showMessage(result);
            setOtpSent(true);
            setResendTime(ONE_MINUTE);
            setOtpSentTo({ mobile, channel: "SMS" });
            setTimers();
          })
          .catch((e) => {
            showMessage(e as AxiosError, "error");
          });
      }
    },
    [otpSent, setOtpSent, setResendTime, otpResendTimer, setTimers]
  );

  const [form] = Form.useForm();

  return (
    <AuthForm form={form} onSubmit={onSubmit}>
      <MobileCol style={{ width: "100%" }}>
        <Label htmlFor="mobile-number">Phone number</Label>
        <Spacer size={5} />
        <Form.Item
          name="mobile"
          initialValue={{ short: "IN", code: 91 }}
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "Mobile number is required",
            },
            {
              validator: (_, value) =>
                /^[0-9]{8,14}$/.test(value?.phone)
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("Please input valid contact number!")
                    ),
            },
          ]}
        >
          <CountryPhoneInput
            id="mobile-number"
            autoFocus={true}
            size="large"
            style={{ width: "100%" }}
            onChange={() => {
              if (otpSent) {
                setOtpSent(false);
                setResendTime(ONE_MINUTE);
                setOtpSentTo({
                  mobile: { phone: "", short: "", code: 0 },
                  channel: "SMS",
                });
              }
            }}
          />
        </Form.Item>
        {otpSent ? (
          <>
            <Label htmlFor="otp">OTP</Label>
            <Spacer size={5} />
            <Form.Item
              name="otp"
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "Please input your otp!",
                },
                {
                  len: 4,
                  message: "OTP should be 4 digits long!",
                },
                {
                  pattern: /[0-9]+$/,
                  message: "OTP can only have numeric characters!",
                },
              ]}
            >
              <Input.Password
                size="large"
                id="otp"
                placeholder="OTP"
                prefix={<LockOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
          </>
        ) : null}
      </MobileCol>
      <Spacer size={-10} />
      <Row style={{ flexDirection: "column", alignItems: "center" }}>
        <Link to="/login">
          <ForgotPassword>Sign in with password</ForgotPassword>
        </Link>
        {otpSent ? (
          <ForgotPassword
            style={{ alignSelf: "center" }}
            disabled={resendTime > 0}
            onClick={() => {
              if (resendTime > 0) return;

              const onConfirm = (channel: "SMS" | "WHATSAPP") => {
                const sendOtpDto = {
                  channel,
                  phone: {
                    code: otpSentTo.mobile.code,
                    number: otpSentTo.mobile.phone,
                  },
                };

                axios
                  .post("/auth/otp/send/phone", sendOtpDto)
                  .then((result) => {
                    showMessage(result);
                    setResendTime(ONE_MINUTE);
                    setTimers();
                  })
                  .catch((e) => {
                    showMessage(e as AxiosError, "error");
                  });
              };

              modal.confirm({
                title: "Resend OTP via",
                okText: "SMS",
                cancelText: "WhatsApp",
                onCancel: () => onConfirm("WHATSAPP"),
                onOk: () => onConfirm("SMS"),
              });
            }}
          >
            Resend OTP{" "}
            {resendTime > 0
              ? `in ${String(Math.round(resendTime / 1000)).padStart(2, "0")}s`
              : ""}
          </ForgotPassword>
        ) : null}
      </Row>
      <Spacer size={20} />
      <Form.Item style={{ width: "100%" }}>
        <Button
          block
          type="primary"
          size="large"
          shape="round"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          {otpSent ? "Verify OTP" : "Send OTP"}
        </Button>
      </Form.Item>
      <RegisterText>
        New to PocketFilms?{" "}
        <p
          onClick={() =>
            navigate(
              "/get-started" + (search.get("r") ? `?r=${search.get("r")}` : "")
            )
          }
        >
          Register
        </p>
      </RegisterText>
      <Spacer size={10} />
      <OrContainer>
        <Line></Line>
        <OrText>Or</OrText>
        <Line></Line>
      </OrContainer>
      <Spacer size={10} />
      <GoogleButton href={`${config.API_BASE_URL}/auth/google/login`}>
        <GoogleIcon />
      </GoogleButton>
    </AuthForm>
  );
};

const MobileCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ForgotPassword = styled.span<{ disabled?: boolean }>`
  text-align: right;
  font-size: 1.6rem;
  color: #2268bd;
  font-weight: 600;
  cursor: pointer;
  font-style: italic;
  align-self: flex-end;
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
`;

const RegisterText = styled.h3`
  font-size: 1.6rem;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  text-align: center;

  & text {
    color: #2268bd;
    cursor: pointer;
  }
`;

const OrContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const Line = styled.div`
  height: 1px;
  background-color: #262525;
  flex: 1;
`;

const OrText = styled(RegisterText)`
  opacity: 0.8;
  text-transform: uppercase;
`;

const GoogleButton = styled.a`
  padding: 1.5rem;
  cursor: pointer;
  border-radius: 50%;
  background-color: #141314;
  transition: transform 0.1s ease-in-out;

  &:hover {
    transform: translateY(-0.5rem);
  }
`;

const GoogleIcon = styled.img.attrs({ src: "/images/google.png" })`
  width: 3rem;
  height: 3rem;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media only screen and (max-width: 31.25em) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;
