export abstract class Model<T extends Record<string, any>> {
  public abstract toJson(): T;

  public copyWith<U extends keyof T>(propertyName: U, newValue: T[U]) {
    return (this as any).fromJson({
      ...this.toJson(),
      [propertyName]: newValue,
    });
  }
}
