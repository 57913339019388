import { useCallback, useState } from "react";
import styled from "styled-components";
import { Spacer } from "../../components/spacer/spacer.component";
import { axios } from "../../axios";
import { useSetAtom } from "jotai";
import { authAtom } from "../../auth/auth.atom";
import { UserInterface } from "../../interfaces/user.interface";
import { User } from "../../models/User";
import { AxiosError } from "axios";
import { wait } from "../../utils/wait";
import { Button, Checkbox, DatePicker, Form, Input } from "antd";
import CountryPhoneInput from "antd-country-phone-input";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { Moment } from "moment";
import { Link, useSearchParams } from "react-router-dom";
import { AuthForm } from "../../components/auth-form/auth-form.component";
import { config } from "../../config";
import { showMessage } from "../../utils/showMessage";

export const SignupPage = () => {
  const [search] = useSearchParams();

  const setAuth = useSetAtom(authAtom);
  const navigate = useNavigate();
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const [form] = Form.useForm();

  const onSubmit = useCallback(
    async ({
      email,
      mobile,
      birthdate,
      password,
      firstName,
      lastName,
    }: {
      email: string;
      mobile: { phone: string; code: number };
      birthdate: Moment | string;
      password: string;
      firstName: string;
      lastName: string;
    }) => {
      try {
        const signupDto = {
          email: email,
          phone: {
            number: mobile.phone,
            code: mobile.code,
          },
          password: password,
          birth_date: (birthdate as Moment).format("DD-MM-YYYY"),
          first_name: firstName,
          last_name: lastName,
        };

        const result = await axios.post<{
          token: string;
          user: UserInterface;
          message?: string;
        }>("/auth/create/user", signupDto);
        if (result.status === 200) {
          showMessage(result);
          await wait(1000);
          setAuth({
            authenticated: true,
            user: User.fromJson(result.data.user),
            loaded: true,
          });
          navigate("/");
        }
      } catch (e) {
        showMessage(e as AxiosError, "error");
      }
    },
    []
  );

  return (
    <AuthForm form={form} onSubmit={onSubmit}>
      <NameRow>
        <Form.Item name="firstName" rules={[{ required: true }]}>
          <Input
            size="large"
            style={{ width: "100%" }}
            placeholder="First Name"
            name="firstName"
          />
        </Form.Item>
        <Form.Item name="lastName" rules={[{ required: true }]}>
          <Input
            size="large"
            style={{ width: "100%" }}
            placeholder="Last Name"
            name="lastName"
          />
        </Form.Item>
      </NameRow>
      <Form.Item
        style={{ width: "100%" }}
        name="email"
        rules={[
          {
            type: "email",
            required: true,
            message: "Please input your Email address!",
            transform: (value) => value.toLowerCase(),
          },
        ]}
      >
        <Input
          size="large"
          style={{ width: "100%" }}
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="mobile"
        style={{ width: "100%" }}
        initialValue={{ short: "IN" }}
        rules={[
          {
            required: true,
            message: "Mobile number is required",
          },
          {
            validator: (_, value) =>
              /^[0-9]{8,14}$/.test(value?.phone)
                ? Promise.resolve()
                : Promise.reject(
                    new Error("Please input valid contact number!")
                  ),
          },
        ]}
      >
        <CountryPhoneInput size="large" style={{ width: "100%" }} />
        {/* <Input size="large" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Mobile Number" /> */}
      </Form.Item>
      <Form.Item
        name="password"
        style={{ width: "100%" }}
        rules={[
          {
            type: "string",
            required: true,
            message: "Please input your Password!",
          },
          {
            min: 8,
            max: 24,
            message: "Please input your Password between 8 to 24 characters!",
          },
          {
            pattern: /^[a-zA-Z0-9<>!@#$%^&{}[\]()?_+-=]+$/,
            message: "Invalid characters entered!",
          },
        ]}
      >
        <Input.Password
          size="large"
          style={{ width: "100%" }}
          placeholder="Password"
          prefix={<LockOutlined className="site-form-item-icon" />}
        />
      </Form.Item>
      <Form.Item
        name="birthdate"
        style={{ width: "100%" }}
        rules={[
          {
            type: "date",
            transform: (value) => value.toString(),
          },
          {
            required: true,
            message: "Please input your birth date!",
          },
        ]}
      >
        <DatePicker
          format={"DD-MM-YYYY"}
          size="large"
          style={{ width: "100%" }}
          // disabledDate={disabledDate}
          placeholder="Birth date"
        />
      </Form.Item>
      <Form.Item
        name="terms"
        style={{ width: "100%" }}
        valuePropName="checked"
        initialValue={false}
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Checkbox
          style={{ width: "100%" }}
          defaultChecked
          onChange={(evt) => setAcceptedTerms(evt.target.checked)}
        >
          Accept{" "}
          <Link
            target={"_blank"}
            to={"https://dev.pocketfilms.in/terms-of-use"}
          >
            Terms and Conditions
          </Link>
        </Checkbox>
      </Form.Item>
      <Spacer size={-25} />
      <NameRow>
        <Form.Item
          name="subscribeMobile"
          initialValue={true}
          valuePropName="checked"
        >
          <Checkbox
            style={{ width: "100%", textWrap: "nowrap" }}
            defaultChecked
            checked={true}
          >
            Mobile Updates
          </Checkbox>
        </Form.Item>
        <Spacer size={-25} />
        <Form.Item
          name="subscribeNewsletter"
          initialValue={true}
          valuePropName="checked"
        >
          <Checkbox style={{ width: "100%" }} defaultChecked>
            Newsletters
          </Checkbox>
        </Form.Item>
      </NameRow>

      <Form.Item style={{ width: "100%" }}>
        <Button
          block
          type="primary"
          size="large"
          shape="round"
          htmlType="submit"
          style={{ width: "100%" }}
          disabled={!acceptedTerms}
        >
          Sign up
        </Button>
      </Form.Item>
      <RegisterText>
        Already have an account?{" "}
        <p
          onClick={() =>
            navigate(
              "/login" + (search.get("r") ? `?r=${search.get("r")}` : "")
            )
          }
        >
          Log in
        </p>
      </RegisterText>
      <Spacer size={10} />
      <OrContainer>
        <Line></Line>
        <OrText>Or</OrText>
        <Line></Line>
      </OrContainer>
      <Spacer size={10} />
      <GoogleButton href={`${config.API_BASE_URL}/auth/google/login`}>
        <GoogleIcon />
      </GoogleButton>
    </AuthForm>
  );
};

const NameRow = styled.div`
  width: 100%;
  display: flex;
  gap: 1.5rem;

  & > * {
    flex: 1;
  }
`;

const GoogleButton = styled.a`
  padding: 1.5rem;
  cursor: pointer;
  border-radius: 50%;
  background-color: #141314;
  transition: transform 0.1s ease-in-out;

  &:hover {
    transform: translateY(-0.5rem);
  }
`;

const RegisterText = styled.h3`
  font-size: 1.6rem;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  text-align: center;

  & text {
    color: #2268bd;
    cursor: pointer;
  }
`;

const OrContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  align-items: center;
`;

const Line = styled.div`
  height: 1px;
  background-color: #262525;
  flex: 1;
`;

const OrText = styled(RegisterText)`
  opacity: 0.8;
  text-transform: uppercase;
`;

const GoogleIcon = styled.img.attrs({ src: "/images/google.png" })`
  width: 3rem;
  height: 3rem;
`;
